import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './services.module.css'
import { AiOutlineHtml5 } from 'react-icons/ai'
import { IoLogoJavascript } from 'react-icons/io'
import { DiCss3Full, DiLaravel, DiMongodb, DiFirebase } from 'react-icons/di'
import { FaReact, FaNodeJs, FaPhp, FaApple, FaAws } from 'react-icons/fa'
import { GrSwift, GrMysql, GrGatsbyjs } from 'react-icons/gr'

export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="About" description="Who are we?" />
      <h1 className={styles.title}>Services</h1>
      <div className={styles.services}>
        <div className={styles.service}>
          <h3>Web Development</h3>
          <ul>
            <li>Websites</li>
            <li>Web Apps</li>
            <li>Custom CMS</li>
            <li>E-Commerce</li>
            <li>RESTful API</li>
          </ul>
        </div>
        <div className={styles.service}>
          <h3>Mobile Apps Development</h3>
          <ul>
            <li>Native iOS App</li>
            <li>Cross-platform Apps (iOS and Android)</li>
            <li>AWS | Firebase</li>
          </ul>
        </div>
      </div>
      <h2 className={styles.title}>Our Tech Stack</h2>
      <div className={styles.techStack}>
        <div className={styles.techItem}>
          <AiOutlineHtml5 fontSize={42} />
          <div>HTML</div>
        </div>
        <div className={styles.techItem}>
          <IoLogoJavascript fontSize={42} />
          <div>JavaScript</div>
        </div>
        <div className={styles.techItem}>
          <DiCss3Full fontSize={42} />
          <div>CSS</div>
        </div>
        <div className={styles.techItem}>
          <FaReact fontSize={42} />
          <div>React</div>
        </div>
        <div className={styles.techItem}>
          <GrGatsbyjs fontSize={42} />
          <div>Gatsby</div>
        </div>
        <div className={styles.techItem}>
          <FaNodeJs fontSize={42} />
          <div>Node.js</div>
        </div>
        <div className={styles.techItem}>
          <FaPhp fontSize={42} />
          <div>PHP</div>
        </div>
        <div className={styles.techItem}>
          <DiLaravel fontSize={42} />
          <div>Laravel</div>
        </div>
        <div className={styles.techItem}>
          <GrSwift fontSize={42} />
          <div>Swift</div>
        </div>
        <div className={styles.techItem}>
          <FaApple fontSize={42} />
          <div>Objective C</div>
        </div>
        <div className={styles.techItem}>
          <DiMongodb fontSize={42} />
          <div>MongoDB</div>
        </div>
        <div className={styles.techItem}>
          <GrMysql fontSize={42} />
          <div>MySQL</div>
        </div>
        <div className={styles.techItem}>
          <FaAws fontSize={42} />
          <div>AWS</div>
        </div>
        <div className={styles.techItem}>
          <DiFirebase fontSize={42} />
          <div>Firebase</div>
        </div>
      </div>
    </Layout>
  )
}
